import React from "react"
import Helmet from "react-helmet"
import Layout from "../templates/Layout"
import Container from "../components/Container"
import { OutboundLink } from "gatsby-plugin-google-analytics"
import { withLoginStateContext } from "../contexes/LoginStateContext"

const Credits = () => (
  <Layout>
    <Container>
      <Helmet title="Credits and about the course material" />
      <h1>Credits and about the course material</h1>

      <h2>Course material</h2>

      <p>
        The course material was written by{" "}
        <OutboundLink
          href="https://github.com/jttoivon"
          target="_blank"
          rel="noopener noreferrer"
        >
          Jarkko Toivonen
        </OutboundLink>{" "}
        . It was updated by{" "}
        <OutboundLink
          href="https://github.com/saskeli"
          target="_blank"
          rel="noopener noreferrer"
        >
          Saska Dögnes
        </OutboundLink>{" "}
        .
      </p>
      <p>
        The course material is licensed under the{" "}
        <OutboundLink
          href="https://creativecommons.org/licenses/by-nc-sa/4.0/deed.fi"
          target="_blank"
          rel="noopener noreferrer"
        >
          Creative Commons BY-NC-SA 4.0
        </OutboundLink>{" "}
        -license, so you are free to use and distribute it as long as the names
        of the original authors are not removed. If you make changes to the
        material and want to distribute the modified version, you must license
        it under the same license. The use of materials for commercial purposes
        is prohibited without special permission.
      </p>

      <h2>Technology used on the course</h2>

      <p>
        Course page made by{" "}
        <OutboundLink
          href="https://github.com/nygrenh"
          target="_blank"
          rel="noopener noreferrer"
        >
          Henrik Nygren
        </OutboundLink>{" "}
        and{" "}
        <OutboundLink
          href="https://github.com/redande"
          target="_blank"
          rel="noopener noreferrer"
        >
          Antti Leinonen
        </OutboundLink>
        . University of Helsinki&#39;s{" "}
        <OutboundLink
          href="https://www.helsinki.fi/en/researchgroups/data-driven-education"
          target="_blank"
          rel="noopener noreferrer"
        >
          Agile Education Research Group
        </OutboundLink>{" "}
        has created the programming assignment evaluator (
        <OutboundLink
          href="https://tmc.mooc.fi"
          target="_blank"
          rel="noopener noreferrer"
        >
          Test My Code
        </OutboundLink>
        ) used on the course, including its plugins for programming
        environments, quiz system in the material, and other functionality.
      </p>
    </Container>
  </Layout>
)

export default withLoginStateContext(Credits)
